const languages = {
    en: {
        getYourDesign: "Get Your Design in a Second",
        budget: "Budget",
    },
    arabic: {
        budget: "ميزانية",
    }
};

export default languages;
