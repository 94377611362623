// import './styles/index.css';
import Layout from './components/layout/Layout';

function App() {
  return (
    <div className="mood-board-editor-lite">
     <Layout />
    </div>
  );
}

export default App;
