import {useEffect, useRef, useState} from "react";
import dislikeIcon from "../../assets/icons/dislike.svg";
import heartIcon from "../../assets/icons/heart.svg";
import shareIcon from "../../assets/icons/arrow-share.svg";
import bookmarkIcon from "../../assets/icons/bookmark.svg";
import cartIcon from "../../assets/icons/cart.svg";
import replaceArrows from "../../assets/icons/replace-arrows.svg";
import {PinnedIcon, SwapItemsNextIcon, SwapItemsPreviousIcon} from "../../assets/icons";

import cls from "./manager.module.css";
import PageLoader from "../common/PageLoader";
import {useSelector} from "react-redux";
import axios from "axios";
import {EndPoints} from "../../config/EndPoints";
import AppConfig from "../../config/AppConfig";
import ButtonLoader from "../common/ButtonLoader";


export default function Manager( { layouts, moodBoards }){
    const swapItemsRef                                              = useRef();
    const canvasRef                                                 = useRef();
    const { isMobileView }                                          = useSelector(state => state.project);
    const [isLoadingData, setIsLoadingData]                         = useState(false);
    const [loadingSwapItems, setLoadingSwapItems]                   = useState(false);
    const [loadingAddToCart, setLoadingAddToCart]                   = useState(false);
    const [selectedDesignData, setSelectedDesignData]               = useState({});
    const [showSwapPanel, setShowSwapPanel]                         = useState(null);
    const [swapItemsList, setSwapItemsList]                         = useState();
    const [current, setCurrent]                                     = useState(1);
    const [budget, setBudget]                                       = useState("");
    const [currencyUnit, setCurrencyUnit]                           = useState("");

    const DIMENSION = isMobileView ? 370 : 528;
    const LIMIT = 3;
    const PAGES = Math.floor(swapItemsList?.length / LIMIT);
    const swapItemsPerPage = swapItemsList?.slice((current - 1) * LIMIT, current * LIMIT);

    const getImageDimensions = (src) => {
        let data;
        const imgObj = new Image();
        imgObj.src = src;

        imgObj.onload = function () {
            data = { width: imgObj.width, height: imgObj.height}
        };
        return data;
    }

    const getImagePosition = (images, position) => {
        if(images[position]) return position;
        if(images['front']) return 'front';
        if(images['left']) return 'left';
        return 'right';
    }

    const handleSelectedDesign = async (data) => {
        setIsLoadingData(true);
        setShowSwapPanel(null);
        const { Items, moodboard_Template_ID, moodboard_id, design_id, currency } = data;
        setCurrencyUnit(currency || "SAR");
        const matchLayout = layouts.find(e => e.mood_Template_ID === moodboard_Template_ID);
        const {items, mood_board_canvas} = matchLayout.template;

        const ratio = DIMENSION / mood_board_canvas.width;

        let newData = [];

        const pinnedItems = selectedDesignData.items?.filter(i => i.pinned !== null);

        for(let i=0;i<items.length;i++){
            const { category, left, top, position } = items[i];
            const matchedData = Items.find(e => e.category.toLowerCase() === category.toLowerCase());
            const sameCategoryItems = items.filter(i => i.category === category);
            let indexInSameCategory = sameCategoryItems.indexOf(items[i]);
            if(matchedData){
                let obj = {};
                const itemPinned = pinnedItems?.find(e => e.pinned === indexInSameCategory && e.category === matchedData.category);
                const image = matchedData.Images[getImagePosition(matchedData.Images, position)];
                if(itemPinned){
                    obj = {...itemPinned}
                } else {
                    if(image){
                        obj = {
                            ...matchedData,
                            position,
                            ratio,
                            imgSrc: image,
                            actualWidth: items[i].width,
                            actualHeight: items[i].height,
                            width: (items[i].width) * ratio,
                            height: (items[i].height) * ratio,
                            left: left * ratio,
                            top: top * ratio,
                            pinned: null,
                        };
                    }
                }
                if(itemPinned || image) newData.push(obj);
            }
            if(i === items.length - 1){
                setIsLoadingData(false);
            }
        };
        let _data = {
            id: moodboard_id,
            designId: design_id,
            items: newData
        }
        console.log('Selected DATA00000000', _data);
        setSelectedDesignData(_data)
        calculateTotalPrice(newData.map(e => ({price: e.price})));
    };

    const previousDesign = () => {
        const index = moodBoards.findIndex(i => i.moodboard_id === selectedDesignData.id);
        if(index === 0){
            handleSelectedDesign(moodBoards[moodBoards.length-1])
        } else {
            handleSelectedDesign(moodBoards[index - 1])
        }
    }

    const nextDesign = () => {
        const index = moodBoards.findIndex(i => i.moodboard_id === selectedDesignData.id);
        if(index === moodBoards.length-1){
            handleSelectedDesign(moodBoards[0])
        } else {
            handleSelectedDesign(moodBoards[index + 1])
        }
    }

    const getSwapItemsList = (id, category) => {
        setLoadingSwapItems(true);
        axios.get(`${EndPoints.getSwapItems}?item_id=${id}&design_id=${selectedDesignData.designId}`)
            .then(res => {
                setLoadingSwapItems(false);
                setSwapItemsList(res.data.similarProducts)
            })
            .catch(err => {
                setCurrent(1);
                setLoadingSwapItems(false);
                setShowSwapPanel(null);
                setSwapItemsList(null);
                console.log(err)
            })
    }

    const toggleSwapPanel = (id, index, category) => {
        if(index === undefined || showSwapPanel === index){
            setShowSwapPanel(null);
            setSwapItemsList(null);
            return;
        }


        setShowSwapPanel(index)
        getSwapItemsList(id, category);
    }

    const calculateTotalPrice = (data) => {
        const total = data.reduce((acc, currentValue) => acc + Number(currentValue.price), 0);
        setBudget(total);
    };

    const swapImage = async (image, position, price, index) => {
        let data = {...selectedDesignData};
        const {actualWidth, actualHeight, ratio} = data.items[index]
        data.items[index] = {
            ...data.items[index],
            imgSrc: image,
            width: actualWidth * ratio,
            height: actualHeight * ratio,
            price
        }
        setSelectedDesignData(data);
        calculateTotalPrice(data.items);
        toggleSwapPanel(undefined);
    }

    const onPreviousItems = () => {
        if(current === 1) return;
        setCurrent(c => c-1);
    };

    const onNextItems = () => {
        if(current === PAGES) return;
        setCurrent(c => c+1);
    };

    const getSwapListStyle = (left, top, height) => {
        let pos = { left: 0, top: 0};
        const offset = 52;
        if(swapItemsRef.current){
            const { clientHeight, clientWidth } = swapItemsRef.current;
            if(clientWidth + left + offset > DIMENSION){
                pos = {...pos, left: DIMENSION - left - clientWidth - offset}
            }
            if(clientHeight + top + height > DIMENSION){
                pos = {...pos, top: -120}
            }
        }

        return {
            position: 'relative',
            fontSize: '12px',
            maxWidth: DIMENSION * 0.8,
            width: DIMENSION * 0.7,
            left: pos.left,
            top: pos.top,
            zIndex: '9999',
            transform: `translateY(${-height/2}px)`
        };
    }

    const togglePinnedItem = (index, category) => {
        let items = [...selectedDesignData.items];
        let sameCategoryItems = items.filter(e => e.category === category);
        let indexInSameCategory = sameCategoryItems.indexOf(items[index]);
        items[index] = {
            ...items[index],
            pinned: items[index].pinned !== null ? null : indexInSameCategory
        }
        setSelectedDesignData({
            ...selectedDesignData,
            items
        })
    }

    const handleAddToCart = () => {
        setLoadingAddToCart(true);
        setTimeout(() => {
            setLoadingAddToCart(false);
        }, selectedDesignData.items.length * 1500);
        window.parent.window.postMessage({
            action: 'addToCart',
            skus: selectedDesignData.items.map(e => ({sku: e.id, quantity: 1}))
        }, AppConfig.PARENT_APP_URL)
    }

    useEffect(() => {
        const clickOutSide = (event) => {
            if(canvasRef.current && !canvasRef.current.contains(event.target)){
                setShowSwapPanel(null);
            }
        };
        document.body.addEventListener('click', clickOutSide);

        return () => {
            document.body.removeEventListener('click', clickOutSide);
        }
    }, []);

    useEffect(() => {
        if(moodBoards.length > 0){
            handleSelectedDesign(moodBoards[0]);
        }
    }, [moodBoards]);

    return (
        <>
            <div className="flex justify-center" id="canvas-wrapper">
                <div style={{maxWidth: DIMENSION}}>
                    <div
                        style={{width: DIMENSION, height: DIMENSION}}
                        className="relative border-2 border-black rounded-2xl canvas-container flex flex-col justify-center overflow-hidden">
                        {isLoadingData ? (
                            <PageLoader text="Loading Items..." />
                        ) : (
                            <div ref={canvasRef}>
                                {selectedDesignData?.items?.map( ({id, width, height, left, top, imgSrc, price, category, position, items, pinned}, index) => {
                                    return (
                                        <div key={index} className="absolute" style={{width, height, left, top, userSelect: 'none'}}>
                                            <img src={imgSrc} width={width} height={height} className="object-contain h-full" onClick={(event) => toggleSwapPanel(id, index, category)} />
                                            {showSwapPanel === index &&
                                                <div
                                                    ref={swapItemsRef}
                                                    style={getSwapListStyle(left, top, height)}
                                                >
                                                    <button className={cls.swap_items_previous_btn} disabled={current === 1} onClick={onPreviousItems}>
                                                        <SwapItemsPreviousIcon fill={current == 1 && '#738c8b'} />
                                                    </button>
                                                    <button className={cls.swap_items_next_btn} disabled={current === PAGES} onClick={onNextItems} >
                                                        <SwapItemsNextIcon fill={current == PAGES && '#738c8b'} />
                                                    </button>
                                                    <div className={`flex items-center justify-end mb-0.5 px-2`}>
                                                        <div className={`${cls.swap_items_popup_topBar} flex items-center justify-end gap-4 text-sm py-1 px-4`}>
                                                            <div>
                                                                {`${price} ${currencyUnit}`}
                                                            </div>
                                                            <span>|</span>
                                                            <button>
                                                                <img src={replaceArrows} onClick={()=>{}} />
                                                            </button>
                                                            <button onClick={() => togglePinnedItem(index, category)}>
                                                                <PinnedIcon fill={pinned !== null ? 'black' : 'white'} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className={`${cls.swap_items_popup} flex items-center gap-2 justify-center relative p-2 ml-1 overflow-x-auto`}>


                                                        {loadingSwapItems ? <div className="py-4">
                                                            <PageLoader text="Loading Items..." />
                                                        </div> : swapItemsPerPage?.map(({ images, price}) => {
                                                            return (
                                                                <div
                                                                    className="flex flex-col justify-center items-center cursor-pointer">
                                                                    <div>
                                                                        <img
                                                                            src={images[getImagePosition(images, position)]}
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '100px',
                                                                                objectFit: 'contain'
                                                                            }}
                                                                            onClick={() => swapImage(images[getImagePosition(images, position)], position, price, index)}
                                                                        />
                                                                    </div>
                                                                    <div>{`${price} ${currencyUnit}`}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                </div>}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        {/*<div className="relative" style={{width: (window.innerWidth/(isMobileView ? 1 : 2)) * 0.955, height: window.innerHeight * 0.6}}>*/}

                        {/*</div>*/}
                        {/*<div className="flex justify-center items-center" style={{transform: 'translateY(75px)'}}>*/}
                        {/*    <div*/}
                        {/*        className="flex justify-center items-center rounded"*/}
                        {/*        style={{backgroundColor: "#71CFBC", width: "308px", height: "67px"}}*/}
                        {/*    >*/}
                        {/*        <FormattedMessage id="budget" />: $3000*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="flex gap-2 py-5">
                        <div className={`flex items-center ${isMobileView ? 'gap-2' : 'gap-5'}`}>
                            <button>
                                <img src={dislikeIcon} />
                            </button>
                            <button>
                                <img src={heartIcon} />
                            </button>
                            <button>
                                <img src={shareIcon} />
                            </button>
                            <button>
                                <img src={bookmarkIcon} />
                            </button>
                        </div>
                        <div className="grow"></div>
                        <button className="primary-btn py-2 px-5" disabled={isLoadingData} onClick={previousDesign}>
                            Previous
                        </button>
                        <button className="primary-btn py-2 px-8" disabled={isLoadingData} onClick={nextDesign}>
                            Next
                        </button>
                    </div>
                    <div className="p-2 flex items-center justify-between text-sm rounded-md" style={{backgroundColor: '#313149', color: 'white'}}>
                        {loadingAddToCart ? <ButtonLoader text="Adding to Cart..."/> :
                            <button className="flex item-center gap-1" onClick={handleAddToCart}>
                                <img src={cartIcon}/>
                                <span className='pt-1'>Add to cart</span>
                            </button>}
                        <div>
                            {`${budget} ${currencyUnit}`}
                        </div>
                    </div>
                    <div className="text-sm px-3 py-1">
                    You can remove or change item Quantity from cart
                    </div>
                </div>
            </div>
        </>
    )
}
