export const LivingRoomIcon = ({fill}) =>  <svg width="21" height="20" viewBox="0 0 21 20" fill={fill || 'none'} xmlns="http://www.w3.org/2000/svg">
    <path d="M5.20937 8.33333V11.6667H15.2645V8.33333C15.2645 6.49479 16.1663 5 17.2755 5H18.281C18.281 2.23958 16.9298 0 15.2645 0H5.20937C3.544 0 2.19284 2.23958 2.19284 5H3.19835C4.30756 5 5.20937 6.49479 5.20937 8.33333ZM18.281 6.66667H17.2755C16.7193 6.66667 16.27 7.41146 16.27 8.33333V13.3333H4.20386V8.33333C4.20386 7.41146 3.75453 6.66667 3.19835 6.66667H2.19284C1.08364 6.66667 0.181824 8.16146 0.181824 10C0.181824 11.2292 0.590312 12.2917 1.18733 12.8698V19.1667C1.18733 19.625 1.41357 20 1.69009 20H3.70111C3.97762 20 4.20386 19.625 4.20386 19.1667V18.3333H16.27V19.1667C16.27 19.625 16.4962 20 16.7727 20H18.7838C19.0603 20 19.2865 19.625 19.2865 19.1667V12.8698C19.8835 12.2917 20.292 11.2292 20.292 10C20.292 8.16146 19.3902 6.66667 18.281 6.66667Z" fill={fill || 'none'}/>
</svg>

export const MagicStars = ({fill}) => <svg width="24" height="24" viewBox="0 0 24 24" fill={fill || 'none'} xmlns="http://www.w3.org/2000/svg">
    <path d="M22.8586 14.5194L18.3657 12.673C17.2791 12.2277 16.4137 11.41 15.9409 10.3891L13.9806 6.15723C13.8285 5.8291 13.3336 5.8291 13.1815 6.15723L11.2212 10.3891C10.7484 11.4126 9.88024 12.2277 8.79641 12.673L4.29793 14.522C3.94956 14.6653 3.94956 15.1288 4.29793 15.272L8.87383 17.1783C9.95766 17.6289 10.8203 18.4492 11.2876 19.4753L13.1843 23.6264C13.3336 23.9571 13.8313 23.9571 13.9833 23.6264L15.9409 19.4023C16.4137 18.3789 17.2819 17.5638 18.3657 17.1184L22.8586 15.272C23.207 15.1288 23.207 14.6626 22.8586 14.5194Z" fill={fill || 'white'}/>
    <path d="M4.78731 11.7635L5.70525 9.78169C5.92644 9.30251 6.33288 8.91969 6.84162 8.71135L8.94847 7.84675C9.11159 7.77904 9.11159 7.56028 8.94847 7.49518L6.84162 6.63057C6.33288 6.42223 5.92644 6.03941 5.70525 5.56023L4.78731 3.57581C4.71542 3.42216 4.48317 3.42216 4.41405 3.57581L3.4961 5.56023C3.27491 6.03941 2.86847 6.42223 2.35973 6.63057L0.250123 7.49778C0.086995 7.56549 0.086995 7.78164 0.250123 7.84935L2.39568 8.7426C2.90442 8.95355 3.30809 9.33897 3.52652 9.81815L4.41681 11.7635C4.4887 11.9172 4.72095 11.9198 4.79007 11.7635H4.78731Z" fill={fill || 'white'}/>
    <path d="M8.82684 2.76373L9.96597 3.2377C10.2369 3.34968 10.4498 3.55542 10.566 3.80803L11.0388 4.84191C11.0775 4.92525 11.1991 4.92525 11.2378 4.84191L11.7244 3.7898C11.8433 3.53458 12.059 3.33145 12.3272 3.22208L13.4442 2.76373C13.5299 2.72727 13.5299 2.61269 13.4442 2.57623L12.3272 2.11788C12.0562 2.0059 11.8406 1.80277 11.7244 1.55016L11.2378 0.498048C11.1991 0.417317 11.0775 0.417317 11.0388 0.498048L10.5521 1.55016C10.4332 1.80537 10.2176 2.0085 9.94938 2.11788L8.8296 2.57883C8.74389 2.61529 8.74389 2.72988 8.8296 2.76634L8.82684 2.76373Z" fill={fill || 'white'}/>
</svg>

export const PinnedIcon = ({fill}) => <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.85714 5.75V2H8.57143C8.96429 2 9.28571 1.6625 9.28571 1.25C9.28571 0.8375 8.96429 0.5 8.57143 0.5H1.42857C1.03571 0.5 0.714286 0.8375 0.714286 1.25C0.714286 1.6625 1.03571 2 1.42857 2H2.14286V5.75C2.14286 6.995 1.18571 8 0 8V9.5H4.26429V14.75L4.97857 15.5L5.69286 14.75V9.5H10V8C8.81429 8 7.85714 6.995 7.85714 5.75Z" fill={fill}/>
</svg>

export const SwapItemsPreviousIcon = ({fill}) => <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C10.8585 25 9.23303 24.6767 7.71646 24.0485C6.19989 23.4203 4.8219 22.4996 3.66117 21.3388C2.50043 20.1781 1.57969 18.8001 0.951506 17.2835C0.32332 15.767 0 14.1415 0 12.5C0 10.8585 0.32332 9.23303 0.951506 7.71646C1.57969 6.19989 2.50043 4.8219 3.66117 3.66117C4.8219 2.50043 6.19989 1.57969 7.71646 0.951506C9.23303 0.323322 10.8585 0 12.5 0ZM14.8684 19.0789L16.7105 17.2132L12.0105 12.5L16.7105 7.78684L14.8553 5.92105L8.27631 12.5L14.8684 19.0789Z" fill={fill || "#3D8583"} />
</svg>

export const SwapItemsNextIcon = ({fill}) => <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 0C9.18479 0 6.00537 1.31696 3.66117 3.66117C1.31696 6.00537 0 9.18479 0 12.5C0 15.8152 1.31696 18.9946 3.66117 21.3388C6.00537 23.683 9.18479 25 12.5 25C14.1415 25 15.767 24.6767 17.2835 24.0485C18.8001 23.4203 20.1781 22.4996 21.3388 21.3388C22.4996 20.1781 23.4203 18.8001 24.0485 17.2835C24.6767 15.767 25 14.1415 25 12.5C25 10.8585 24.6767 9.23303 24.0485 7.71646C23.4203 6.19989 22.4996 4.8219 21.3388 3.66117C20.1781 2.50043 18.8001 1.57969 17.2835 0.951506C15.767 0.323322 14.1415 0 12.5 0ZM10.1316 19.0789L8.28947 17.2132L12.9895 12.5L8.28947 7.78684L10.1447 5.92105L16.7237 12.5L10.1316 19.0789Z" fill={fill || "#3D8583"} />
</svg>





